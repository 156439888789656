// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-queries-about-index-js": () => import("./../../../src/queries/about/index.js" /* webpackChunkName: "component---src-queries-about-index-js" */),
  "component---src-queries-contact-index-js": () => import("./../../../src/queries/contact/index.js" /* webpackChunkName: "component---src-queries-contact-index-js" */),
  "component---src-queries-homepage-index-js": () => import("./../../../src/queries/homepage/index.js" /* webpackChunkName: "component---src-queries-homepage-index-js" */),
  "component---src-queries-offer-index-js": () => import("./../../../src/queries/offer/index.js" /* webpackChunkName: "component---src-queries-offer-index-js" */),
  "component---src-queries-process-index-js": () => import("./../../../src/queries/process/index.js" /* webpackChunkName: "component---src-queries-process-index-js" */),
  "component---src-queries-project-index-js": () => import("./../../../src/queries/project/index.js" /* webpackChunkName: "component---src-queries-project-index-js" */),
  "component---src-queries-projects-index-js": () => import("./../../../src/queries/projects/index.js" /* webpackChunkName: "component---src-queries-projects-index-js" */),
  "component---src-queries-showcase-index-js": () => import("./../../../src/queries/showcase/index.js" /* webpackChunkName: "component---src-queries-showcase-index-js" */),
  "component---src-queries-standard-index-js": () => import("./../../../src/queries/standard/index.js" /* webpackChunkName: "component---src-queries-standard-index-js" */)
}

