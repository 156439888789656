export const SELECT_ALL = 'SELECT_ALL'
export const SELECT_TO_SELL = 'SELECT_TO_SELL'

export const setSelectToAll = () => ({
  type: SELECT_ALL
})

export const setSelectToSell = () => ({
  type: SELECT_TO_SELL
})
